import React, { useEffect } from "react";
import _ from "lodash";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";

import { Layout, SEO, CTASimple } from "../components";
import { metaDateFormat } from "../misc/utils";
import classNames from "classnames";
import { capture } from "../common/analytics";

const Stats = ({ story }) => {
  const { stats } = story;
  const colClass = [2, 4].includes(stats.length)
    ? "sm:grid-cols-2"
    : "sm:grid-cols-3";

  return (
    <div className="bg-white">
      <div className="flex flex-col mx-auto px-8 pt-8 lg:pt-16 max-w-2xl md:max-w-3xl lg:max-w-6xl">
        <div className="relative">
          <div className="absolute inset-0 h-1/2" />
          <div className="relative max-w-7xl mx-auto">
            <dl
              className={`rounded-lg bg-white border border-gray-200 sm:grid ${colClass}`}
            >
              {story.stats.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col border-b border-gray-100 p-6 text-center border-0 border-r"
                >
                  <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                    {item.text}
                  </dt>
                  <dd className="order-1 text-4xl font-semibold text-blue-500">
                    {item.value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

const SuccessStoryTemplate = ({ data }) => {
  const story = data.strapiSuccessStories;
  const { slug } = story;
  useEffect(() => {
    capture("success_story_viewed", { slug });
  }, [slug]);
  const callToAction = story.call_to_action;
  const stories = data.allStrapiSuccessStories;
  const headerImage = getImage(story.gallery[0].image.localFile);
  const fallbaclHeaderImage = headerImage.images.fallback.src;
  const trimmedDescription = story.description.substring(0, 160);
  const metadata = data.site.siteMetadata;

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Article",
    headline: story.title,
    description: trimmedDescription,
    image: fallbaclHeaderImage,
    author: {
      "@type": "Organization",
      name: metadata.title,
    },
    publisher: {
      "@type": "Organization",
      name: metadata.title,
      logo: {
        "@type": "ImageObject",
        url: metadata.defaultImage,
      },
    },
    datePublished: metaDateFormat(story.published_at),
    dateModified: metaDateFormat(story.updated_at),
  };

  const ImageGallery = () => {
    const images = story.gallery.map((item) => {
      const image = getImage(item.image.localFile);
      return {
        image: image,
        alt: item.alt,
      };
    });

    const styles = {
      wrapper:
        "mt-16 mx-auto sm:px-6 lg:px-8 max-w-2xl md:max-w-3xl lg:max-w-6xl lg:grid lg:gap-x-4 px-8 md:px-0",
      twoCols: "lg:grid-cols-2",
    };

    const wrapperClassName = classNames(
      styles.wrapper,
      images.length > 1 && styles.twoCols,
    );

    return (
      <div className={wrapperClassName}>
        {images.length > 0 && (
          <div className="rounded-lg overflow-hidden lg:block">
            <GatsbyImage
              alt={images[0].alt}
              className="w-full h-full object-center object-cover"
              image={images[0].image}
            />
          </div>
        )}

        {images.length == 2 && (
          <div className="lg:grid lg:grid-cols-1 lg:gap-y-4">
            <div className="aspect-w-4 aspect-h-2 lg:aspect-w-2 rounded-lg overflow-hidden mt-4 lg:mt-0">
              <GatsbyImage
                alt={images[1].alt}
                className="w-full h-full object-center object-cover"
                image={images[1].image}
              />
            </div>
          </div>
        )}

        {images.length == 3 && (
          <div className="grid grid-cols-2 lg:grid-cols-1 lg:gap-y-4 space-x-4 mt-4 lg:space-x-0 lg:mt-0">
            <div className="rounded-lg overflow-hidden">
              <GatsbyImage
                alt={images[1].alt}
                className="w-full h-full object-center object-cover"
                image={images[1].image}
              />
            </div>
            <div className="rounded-lg overflow-hidden">
              <GatsbyImage
                alt={images[2].alt}
                className="w-full h-full object-center object-cover"
                image={images[2].image}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const Highlights = () => {
    return (
      <div className="space-y-8 sm:space-y-6">
        {story.highlights.map((item, index) => (
          <div key={index}>
            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
              {item.text}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
              <p>{item.value}</p>
            </dd>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Layout>
      <SEO
        title={story.title}
        description={trimmedDescription}
        schemaMarkup={schemaMarkup}
        image={fallbaclHeaderImage}
      />
      <nav className="px-8 py-8 mx-auto max-w-2xl md:max-w-7xl border-b border-gray-200">
        <ol role="list" className="flex items-center space-x-2">
          <li>
            <div>
              <Link
                to="/historias-de-exito"
                className="text-blue-400 hover:text-gray-500"
              >
                Historias de Éxito
              </Link>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <div className="ml-2 text-sm font-base text-gray-500">
                {story.title}
              </div>
            </div>
          </li>
        </ol>
      </nav>

      <article>
        <div className="bg-white">
          <div className="mx-auto px-8 pt-16 lg:pt-24 max-w-2xl md:max-w-3xl">
            <div className="text-center">
              {story.title && (
                <h1 className="tracking-tight font-bold text-gray-900 text-4xl md:text-5xl">
                  <span className="block">{story.title}</span>
                </h1>
              )}
              {story.description && (
                <h2 className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                  {story.description}
                </h2>
              )}
            </div>
          </div>
        </div>
        <ImageGallery />
        <div className="bg-white pt-8">
          {/* Stats */}
          <Stats story={story} />
          {/* Detail */}
          <div className="mx-auto pt-10 px-8 sm:px-6 max-w-2xl md:max-w-3xl lg:max-w-6xl lg:pt-16">
            <div className="flex flex-col lg:flex-row lg:space-x-12">
              <div className="w-full lg:w-3/5 py-4 md:text-justify order-2 lg:order-1">
                <div className="prose mt-4">
                  <ReactMarkdown>{story.content}</ReactMarkdown>
                </div>
              </div>
              <div className="w-full lg:w-2/5 order-1 lg:order-2">
                <div className="lg:max-w-xl mx-auto bg-gray-50 px-8 py-16 lg:p-8 rounded-lg">
                  {story.title && (
                    <h3 className="font-semibold text-xl text-gray-900 sm:text-xl mb-4">
                      {story.title}
                    </h3>
                  )}
                  <Highlights />
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>

      {callToAction && (
        <CTASimple
          title={callToAction.title}
          description={callToAction.description}
          button={callToAction.button}
        />
      )}
    </Layout>
  );
};

export default SuccessStoryTemplate;

export const query = graphql`
  query SuccessStoryTemplate($slug: String!) {
    strapiSuccessStories(slug: { eq: $slug }) {
      slug
      title
      description
      content
      gallery {
        alt
        id
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 700)
            }
          }
        }
      }
      stats {
        text
        value
      }
      highlights {
        text
        value
      }
      call_to_action {
        button {
          newTab
          text
          url
        }
        description
        id
        title
      }
    }
    allStrapiSuccessStories {
      edges {
        node {
          slug
          title
          description
          gallery {
            alt
            id
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 700)
                }
              }
            }
          }
        }
      }
    }

    site {
      siteMetadata {
        title
        defaultImage: image
        siteUrl
      }
    }
  }
`;
